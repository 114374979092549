import { eDiscoveryUrl } from 'src/common/routes';
import { Navigate } from 'react-router-dom';
import useToken from 'src/common/useToken';
import moment from 'moment';
import { setGlobalState, useGlobalState } from 'src/state/globalstate';

export default function ProtectedRoute(props) {
  const { token } = useToken();

  const Expiretime = Number(localStorage.getItem('Expiretime'));
  const newtime = Date.now();

  const startDate = moment(Expiretime);
  const timeEnd = moment(newtime);
  const diff = timeEnd.diff(startDate);
  const diffDuration = moment.duration(diff);
  const diffMin = diffDuration.minutes();
  //alert(diffMin);
  //alert(diffMin);

  if (diffMin > 30) {
    localStorage.clear();
    setGlobalState('loggedin', false);
    setGlobalState('sessionerror', 'Unauthorized');
    return <Navigate to={eDiscoveryUrl.Login} replace />;
  }

  if (!token) {
    return <Navigate to={eDiscoveryUrl.Login} replace />;
  }

  return props.children;
}

export function SessionMaintained(props) {
  const { token } = useToken();
  const URL = window.location.href;
  // //console.log(URL);
  if (URL.includes('Documentview')) {
    // // //console.log('Shery');
  } else {
    if (token) {
      return <Navigate to={eDiscoveryUrl.CaseS3 + '/1'} replace />;
    }
  }

  return props.children;
}
