import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import 'src/style.css';
import { useGlobalState, setGlobalState } from 'src/state/globalstate';
import HeaderMenu from './Menu';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            
        }
`
);

function Header() {
  const [SearchBarWidth] = useGlobalState('SearchBarWidth');
  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      className="header-container"
    >
      <Box
        display="flex"
        alignItems="left"
        style={{ width: '100%', marginTop: '10px' }}
        // style={{marginLeft: SearchBarWidth}}
      >
        <HeaderButtons />
        <HeaderMenu />
      </Box>
      <Box display="flex" alignItems="right">
        <HeaderUserbox />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
