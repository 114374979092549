import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router';
import { setGlobalState, useGlobalState } from 'src/state/globalstate';

function IdleTimer() {
  let navigate = useNavigate();
  const [loggedin] = useGlobalState('loggedin');

  // Set timeout values
  const timeout = 1000 * 60 * 30;

  const promptTimeout = 100;
  const crosstab = false;

  // Modal open state
  const [open, setOpen] = useState(false);

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 30 minutes. Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setOpen(true);
    setRemaining(promptTimeout);
  };

  const onIdle = () => {
    const URL = window.location.href;
    // //console.log(URL);
    if (
      URL.includes('dashboards/casetest/') ||
      URL.includes('dashboards/case/')
    ) {
      // //console.log('Hude Document Uploading');
    } else {
      localStorage.clear();
      navigate('/login');
      setGlobalState('loggedin', false);
      setOpen(false);
      setRemaining(0);
    }
  };

  const onActive = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    setOpen(false);
    setRemaining(0);
  };

  const onAction = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    const time = Date.now();
    localStorage.setItem('Expiretime', String(time));
    setOpen(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout,
    crossTab: true,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
    onAction
  });

  const handleStillHere = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <>
      <div> </div>
    </>
  );
}

export default IdleTimer;
