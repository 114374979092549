import React from 'react';

interface kContext {
  keyword: string;
  setKeyword: (keyword: string) => void;
}

export const Keywordcontext = React.createContext<kContext>({
  keyword: '',
  setKeyword: () => {}
});
